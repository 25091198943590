import React, { Component } from 'react'
import { graphql } from 'gatsby'
import { Switch, Case, Default, When } from 'react-if';

import Layout from '../../../components/theme/Layout'
import Footer from '../../../components/theme/Footer'
import Header from '../../../components/theme/Header'
import ImageHeader from '../../../components/theme/ImageHeader'
import RenderBlock from '../../../components/blocks/RenderBlock'
import sendEvent from '../../../helpers/sendAnalytics';
import Offers from '../../../components/common/Offers'
import PaperHeader from "../../../components/theme/PaperHeader";

class Category extends Component {
  componentWillMount() {
    const { pageContext } = this.props
    const { guid } = pageContext

    const events = {
      fb: 'ViewContent',
      snapchat: 'VIEW_CONTENT'
    };

    switch (guid) {
      // BATHROOM TISSUE
      case '3f28be5e-63ac-434b-9371-8511b5defcaf':
        events.gtag = {
          send_to: 'AW-951001886/vWAMCLvygZMBEJ7GvMUD'
        };
        events.appnexus = 'https://secure.adnxs.com/px?id=1118273&seg=18016672&t=2'
        break;
      // PAPER TOWEL
      case 'fc61d565-257d-40b3-b1c4-34cac3f03193':
        events.gtag = {
          send_to: 'AW-951001886/pCEuCNbX-pIBEJ7GvMUD'
        };
        events.img = ['https://insight.adsrvr.org/track/pxl/?adv=7c99unf&ct=0:gtjt74b&fmt=3'];
        break;
      // FACIAL TISSUE
      case '50063444-203d-4baf-8e1e-629613766fa1':
        break;
      default:
    }

    // EVENT: BuyOnline
    sendEvent(events)
  }

  render() {
    const { data } = this.props

    let type = '';
    if (data.productCategoryPage.guid === '3f28be5e-63ac-434b-9371-8511b5defcaf') {
      type = 'bathroom';
    } else if (data.productCategoryPage.guid === 'fc61d565-257d-40b3-b1c4-34cac3f03193') {
      type = 'papertowel';
    }

    return (
      <Layout
        meta={data.productCategoryPage.meta}
        urls={data.productCategoryPage.urls}
        locale='fr'
      >
        <Header
          urls={data.productCategoryPage.urls}
          locale='fr'
        />

        <When condition={data.productCategoryPage.guid === '3f28be5e-63ac-434b-9371-8511b5defcaf' || data.productCategoryPage.guid === 'fc61d565-257d-40b3-b1c4-34cac3f03193'}>
          <PaperHeader type={type} />
        </When>

        {data.productCategoryPage.guid !== '3f28be5e-63ac-434b-9371-8511b5defcaf' && data.productCategoryPage.guid !== 'fc61d565-257d-40b3-b1c4-34cac3f03193' ?
          (
            <ImageHeader
              guid={data.productCategoryPage.guid}
              title={data.productCategoryPage.description}
              image={data.productCategoryPage.image_url}
              alt={data.productCategoryPage.title_html}
            />
          ) : null }

        <RenderBlock blocks={data.productCategoryPage.blocks} />

        <Switch>
          <Case condition={data.productCategoryPage.guid === '3f28be5e-63ac-434b-9371-8511b5defcaf'}>
            <Footer image={data.productCategoryPage.footer_image} extraRules='*Que notre marque Royale<sup>®</sup> Original.<br><br>Chaque année, les forêts sous la gestion de J.D. Irving, Limited et de ses sociétés affiliées retirent de l’atmosphère une quantité plus grande de carbone que celle émise durant le cycle de vie des produits de papier Royale<sup>®</sup>.' />
          </Case>
          <Case condition={data.productCategoryPage.guid === 'fc61d565-257d-40b3-b1c4-34cac3f03193'}>
            <Footer image={data.productCategoryPage.footer_image} extraRules='Chaque année, les forêts sous la gestion de J.D. Irving, Limited et de ses sociétés affiliées retirent de l’atmosphère une quantité plus grande de carbone que celle émise durant le cycle de vie des produits de papier Royale<sup>®</sup>.<br/><br/>*Nouveaux emballages de rouleaux doubles comparés aux anciens emballages de rouleaux ordinaires' />
          </Case>
          <Case condition={data.productCategoryPage.guid === '50063444-203d-4baf-8e1e-629613766fa1'}>
            <Footer image={data.productCategoryPage.footer_image} extraRules='*Chaque année, les forêts sous la gestion de J.D. Irving, Limited et de ses sociétés affiliées retirent de l’atmosphère une quantité plus grande de carbone que celle émise durant le cycle de vie des produits de papier Royale<sup>®</sup>.' />
          </Case>
          <Case condition={data.productCategoryPage.guid === 'be15610d-872f-4d9e-bb90-d93abc9d844b'}>
            <Footer image={data.productCategoryPage.footer_image} extraRules='*Chaque année, les forêts sous la gestion de J.D. Irving, Limited et de ses sociétés affiliées retirent de l’atmosphère une quantité plus grande de carbone que celle émise durant le cycle de vie des produits de papier Royale<sup>®</sup>.' />

          </Case>
          <Default>
            <Footer image={data.productCategoryPage.footer_image} />
          </Default>
        </Switch>
        <Offers />
      </Layout>
    )
  }
}

export const query = graphql`
query categoryPageFr($guid: String!) {
  productCategoryPage(guid: {eq: $guid}, locale: {eq:"fr"}) {
    guid
    title
    description
    footer_image
    image_url
    blocks {
      __typename
      ...productCategoriesBlockFragment
      ...imageLeftTextRightBlockFragment
      ...imageRightTextLeftBlockFragment
      ...productLandingBlockFragment

      ... on ProductCategoryBlock {
        guid
        id
        modification
        description
        video
        product_header
        logo_image_url
        product_category_image_url
        products {
          id
          title
          title_html
          guid
          images_url
        }
      }
    }
    other_category_1 {
      preview_image_url
      title
      guid
    }
    other_category_2 {
      preview_image_url
      title
      guid
    }
    other_category_3 {
      preview_image_url
      title
      guid
    }
    urls {
      en
      fr
      zh_hant
      zh_hans
    }
    meta {
      description
      keywords
      title
    }
  }
}
`

export default Category
